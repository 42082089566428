import { AppSettings } from './AppSettings';

const root = AppSettings.API_ROOT;

export interface ApiEndpoints {
    signupUser: string;
    loginUser: string;
    logoutUser: (refreshToken: string) => string;
    confirmLogin: (userId: string) => string;
    googleLogin: string;
    youtubeLogin: string;
    emailAvailable: string;
    usernameAvailable: string;
    bnetLogin: string;
    fbLogin: string;
    createNewStripeSubscription: string;
    createNewBraintreeSubscription: string;
    retryStripeSubscriptionPayment: (subscriptionId: string) => string;
    getStripePublishableKey: string;
    loadCurrencySettings: string;
    updateEmailPreferences: string;
    getEmailPreferences: string;
    trackVisit: string;
    trackProductSubscribe: string;
    validatePromoCode: string;
    validateCampaign: string;
    initializePasswordReset: string;
    resetPassword: string;
    changePassword: string;
    getAffiliateEvents: string;
    getAffiliatePayments: (affiliateId: string) => string;
    getAffiliateCampaigns: string;
    getEnabledBannerCampaigns: string;
    getEnabledModalCampaigns: string;
    getEnabledGiveaways: string;
    getGiveawayRedemptions: (userId: string) => string;
    redeemGiveaway: string;
    getAffiliatePromoCodes: string;
    getAffiliateEarningsData: string;
    getSignedS3Url: string;
    resizeImage: string;
    updateUserImageId: string;
    getUserBilling: string;
    reactivateUserSubscription: string;
    cancelUserSubscription: (subscriptionId: string, userId: string) => string;
    getUserSubscriptionProration: (subscriptionId: string, userId: string) => string;
    changeUserSubscriptionPlan: (subscriptionId: string, userId: string) => string;
    getUserCustomers: (userId: string) => string;
    getUserSubscriptions: (userId: string) => string;
    getUserPayments: (userId: string) => string;
    getUserPaymentMethods: (userId: string) => string;
    addUserPaymentMethod: (userId: string) => string;
    updateUserPaymentMethod: (paymentMethodId: string, userId: string) => string;
    removeUserPaymentMethod: (paymentMethodId: string, userId: string) => string;
    updateUserProfile: string;
    updateUser: (userId: string) => string;
    newUserProfileImageId: (userId: string) => string;
    newAuthToken: string;
    discordLogin: string;
    getVideoGuideSignedCookies: (videoGuideId: string, isLoggedIn: boolean) => string;
    getGCSObjectsSignedURL: (affiliateId: string) => string;
    getGCSObjects: (affiliateId: string) => string;
    moveGCSObjects: (affiliateId: string) => string;
    copyGCSObjects: (affiliateId: string) => string;
    deleteGCSObjects: (affiliateId: string) => string;
    downloadGCSObjects: (affiliateId: string) => string;
    uploadGCSObjectToYoutube: string;
    newPageView: string;
    newAdImpression: string;
    newAdClick: string;
}

export const ApiEndpoints: ApiEndpoints = {
    emailAvailable: root + '/users/email/available',
    usernameAvailable: `${root}/users/username/available`,
    signupUser: root + '/auth/signup',
    loginUser: root + '/auth/login',
    logoutUser: (refreshToken: string) => root + `/auth/logout?refreshToken=${refreshToken}`,
    confirmLogin: userId => `${root}/auth/login/confirm/${userId}`,
    googleLogin: root + '/auth/google',
    youtubeLogin: root + '/auth/youtube',
    bnetLogin: root + '/auth/bnet',
    fbLogin: root + '/auth/fb',
    discordLogin: root + '/auth/discord',
    getStripePublishableKey: root + '/stripe/key',
    loadCurrencySettings: root + '/payments/currency-settings',
    createNewStripeSubscription: root + '/subscriptions/flow/stripe',
    createNewBraintreeSubscription: root + '/subscriptions/flow/braintree',
    retryStripeSubscriptionPayment: (subscriptionId: string) =>
        `${root}/subscriptions/${subscriptionId}/retry`,
    updateEmailPreferences: `${root}/contacts/`,
    getEmailPreferences: `${root}/contacts/`,
    validatePromoCode: root + '/promo-codes/',
    validateCampaign: root + '/campaigns/',
    trackVisit: root + '/affiliate-events/visit',
    trackProductSubscribe: root + '/affiliate-events/product-subscribe',
    initializePasswordReset: root + '/auth/password/reset/initialize',
    resetPassword: root + '/auth/password/reset',
    changePassword: `${root}/auth/password/change`,
    getAffiliateEvents: root + '/affiliate-events/charts',
    getAffiliatePayments: (affiliateId: string): string =>
        `${root}/affiliates/${affiliateId}/earnings/charts`,
    getAffiliateCampaigns: `${root}/campaigns`,
    getEnabledBannerCampaigns: `${root}/campaigns/enabled-banners`,
    getEnabledModalCampaigns: `${root}/campaigns/enabled-modals`,
    getEnabledGiveaways: `${root}/giveaways/enabled`,
    getGiveawayRedemptions: (userId: string) => `${root}/redemptions/${userId}`,
    redeemGiveaway: `${root}/redemptions`,
    getAffiliatePromoCodes: root + '/promo-codes',
    getAffiliateEarningsData: root + '/affiliates/earnings/',
    getSignedS3Url: `${root}/sign/s3`,
    resizeImage: `${root}/images/resize`,
    updateUserImageId: `${root}`,
    getUserBilling: `${root}/billing/user`,
    reactivateUserSubscription: `${root}/subscriptions/user/reactivate`,
    cancelUserSubscription: (subscriptionId: string, userId: string) =>
        `${root}/users/${userId}/subscriptions/${subscriptionId}/cancel`,
    getUserSubscriptionProration: (subscriptionId: string, userId: string) =>
        `${root}/users/${userId}/subscriptions/${subscriptionId}/proration`,
    changeUserSubscriptionPlan: (subscriptionId: string, userId: string) =>
        `${root}/users/${userId}/subscriptions/${subscriptionId}/plan-change`,
    getUserCustomers: (userId: string) => `${root}/users/${userId}/customers`,
    getUserSubscriptions: (userId: string) => `${root}/users/${userId}/subscriptions`,
    getUserPayments: (userId: string) => `${root}/users/${userId}/payments`,
    getUserPaymentMethods: (userId: string) => `${root}/users/${userId}/payment-methods`,
    addUserPaymentMethod: (userId: string) => `${root}/users/${userId}/payment-methods`,
    updateUserPaymentMethod: (paymentMethodId: string, userId: string) =>
        `${root}/users/${userId}/payment-methods/${paymentMethodId}`,
    removeUserPaymentMethod: (paymentMethodId: string, userId: string) =>
        `${root}/users/${userId}/payment-methods/${paymentMethodId}`,
    updateUserProfile: `${root}/auth/user/profile`,
    updateUser: (userId: string) => `${root}/users/${userId}`,
    newUserProfileImageId: (userId: string) => `${root}/users/${userId}/profile-image-ids`,
    newAuthToken: `${root}/auth/tokens`,
    getVideoGuideSignedCookies: (videoGuideId: string, isLoggedIn: boolean) =>
        `${root}/video-guides/${videoGuideId}/signed-cookies?loginCheck=true`,
    getGCSObjectsSignedURL: (affiliateId: string) => `${root}/affiliates/${affiliateId}/files/sign`,
    getGCSObjects: (affiliateId: string) => `${root}/affiliates/${affiliateId}/files`,
    moveGCSObjects: (affiliateId: string) => `${root}/affiliates/${affiliateId}/files/move`,
    copyGCSObjects: (affiliateId: string) => `${root}/affiliates/${affiliateId}/files/copy`,
    deleteGCSObjects: (affiliateId: string) => `${root}/affiliates/${affiliateId}/files`,
    downloadGCSObjects: (affiliateId: string) => `${root}/affiliates/${affiliateId}/files/download`,
    uploadGCSObjectToYoutube: `${root}/gcs/files/upload-to-youtube`,
    newPageView: `${root}/page-views`,
    newAdImpression: `${root}/ad-events/impressions`,
    newAdClick: `${root}/ad-events/clicks`
};

export interface DashboardEndpoints {
    getCourses: string;
    getCourse: (param: string) => string;
    getMasterCourse: string;
    getRecentVideoGuides: string;
    getFeaturedVideoGuides: string;
    getFeaturedCourses: string;
    getTags: string;
    getEnrolledCourses: (userId: string) => string;
    searchVideoGuides: string;
    getMetaData: (userId: string) => string;
    newPlayback: string;
    updatePlayback: (playbackId: string) => string;
    autocompleteResults: string;
    suggestContent: string;
    getNotifications: string;
    markNotificationsSeen: string;
    markNotificationsRead: string;
    newSeenVideo: (userId: string) => string;
    deleteSeenVideo: (userId: string, seenVideoId: string) => string;
    setVideoProgress: (userId: string) => string;
    updateCourseEnrollment: (userId: string) => string;
    getComments: (videoGuideId: string) => string;
    newComment: string;
    updateComment: (commentId: string, userId: string) => string;
    deleteComment: (commentId: string, userId: string) => string;
    likeComment: (userId: string) => string;
    dislikeComment: (userId: string) => string;
    deleteRatedComment: (userId: string, ratedCommentId: string) => string;
    getFeaturedPlayers: string;

    // news
    loadArticles: string;
    loadRecentArticlesByCategory: string;
    loadArticle: (slug: string) => string;
    loadArticleOrCategoryArticles: (slug: string) => string;
    loadCategoryArticles: (slug: string) => string;
    loadCategories: string;
    updateArticleViews: (articleId: string) => string;

    // notif
    updatePushSubscription: string;
    loadNotificationPreferences: string;
    updateNotificationPreferences: string;

    // ad campaigns
    getActiveAdCampaignsAdvertisements: string;
}

export const DashboardEndpoints: DashboardEndpoints = {
    getCourses: `${root}/courses/extended`,
    getCourse: (param: string) => `${root}/courses/${param}`,
    getMasterCourse: `${root}/content-settings`,
    getRecentVideoGuides: `${root}/video-guides/recent`,
    getFeaturedVideoGuides: `${root}/video-guides/featured`,
    getFeaturedCourses: `${root}/courses/featured`,
    getTags: `${root}/courses/tags`,
    getEnrolledCourses: userId => `${root}/courses/${userId}/enrolled`,
    searchVideoGuides: `${root}/video-guides/search`,
    getMetaData: userId => `${root}/users/${userId}/meta`,
    newPlayback: `${root}/playbacks`,
    updatePlayback: (playbackId: string) => `${root}/playbacks/${playbackId}`,
    autocompleteResults: `${root}/video-guides/autocomplete-suggestions`,
    suggestContent: `${root}/content/suggest`,
    getNotifications: `${root}/notifications`,
    markNotificationsSeen: `${root}/notifications/seen`,
    markNotificationsRead: `${root}/notifications/read`,
    newSeenVideo: (userId: string) => `${root}/users/${userId}/seen-videos`,
    deleteSeenVideo: (userId: string, seenVideoId: string) =>
        `${root}/users/${userId}/seen-videos/${seenVideoId}`,
    setVideoProgress: (userId: string) => `${root}/users/${userId}/progress-videos`,
    updateCourseEnrollment: (userId: string) => `${root}/users/${userId}/enrolled-courses`,
    getComments: (videoGuideId: string) => `${root}/comments/video-guide/${videoGuideId}`,
    newComment: `${root}/comments`,
    updateComment: (commentId: string, userId: string) => `${root}/comments/${userId}/${commentId}`,
    deleteComment: (commentId: string, userId: string) => `${root}/comments/${userId}/${commentId}`,
    likeComment: (userId: string) => `${root}/users/${userId}/rated-comments/like`,
    dislikeComment: (userId: string) => `${root}/users/${userId}/rated-comments/dislike`,
    deleteRatedComment: (userId: string, ratedCommentId: string) =>
        `${root}/users/${userId}/rated-comments/${ratedCommentId}`,
    getFeaturedPlayers: `${root}/players/featured`,
    loadArticles: `${root}/articles/recent`,
    loadRecentArticlesByCategory: `${root}/articles/recent-by-category`,
    loadArticle: (slug: string) => `${root}/articles/${slug}`,
    loadArticleOrCategoryArticles: (slug: string) => `${root}/articles/combo/${slug}`,
    loadCategoryArticles: (slug: string) => `${root}/article-categories/${slug}/articles`,
    loadCategories: `${root}/article-categories`,
    updateArticleViews: (articleId: string) => `${root}/articles/${articleId}/views`,
    updatePushSubscription: `${root}/notifications/subscriptions`,
    loadNotificationPreferences: `${root}/receivers/current`,
    updateNotificationPreferences: `${root}/receivers/current`,

    // ad campaigns
    getActiveAdCampaignsAdvertisements: `${root}/ad-campaigns/active/advertisements`
};

