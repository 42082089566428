import { catchError, mergeMap } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { Game } from '../constants/game.enum';
import { IHttp } from '../interfaces/http.interface';
import { HelpersService } from './helpers.service';

@Injectable()
export class BaseHttp implements IHttp {
    protected GAME: Game | string | null | undefined;

    public get: (url: string, body?, options?) => Observable<any>;
    public head: (url: string, body?, options?) => Observable<any>;
    public post: (url: string, body?, options?) => Observable<any>;
    public put: (url: string, body?, options?) => Observable<any>;
    public delete: (url: string, body?, options?) => Observable<any>;

    constructor(private http: HttpClient) {
        this.get = this.makeAction('get');
        this.head = this.makeAction('head');
        this.post = this.makeAction('post');
        this.put = this.makeAction('put');
        this.delete = this.makeAction('delete');
    }

    private toObservable(res: any): Observable<any> {
        if (res.result === null) {
            return observableOf(res.result);
        }

        return observableOf((res && res.result) || res);
    }

    private makeAction: Function = (action: string) => {
        return (url: string, body: any = {}, options: any = {}): Observable<any> => {
            if (action === 'get' || action === 'head') {
                body = {
                    params: new HttpParams({
                        fromObject: HelpersService.makeRequestParams(body, this.GAME)
                    } as any),
                    ...options
                };
            } else if (action === 'delete') {
                body = {
                    body: {
                        ...body,
                        ...(typeof this.GAME !== 'undefined' && { game: this.GAME })
                    },
                    ...options
                };
            } else {
                body = { ...body, ...(typeof this.GAME !== 'undefined' && { game: this.GAME }) };
            }

            if (options.rawRequest) {
                return this.http[action](url, body, { ...options, withCredentials: true });
            }

            return this.http[action](url, body, { ...options, withCredentials: true }).pipe(
                mergeMap(this.toObservable),
                catchError(err => {
                    // if (environment.test || environment.dev) {
                        // console.error('Error handling API request.', err);
                    // }
                    throw HelpersService.handleResponseError(err);
                })
            );
        };
    };
}

