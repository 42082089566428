import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IHttp } from '../interfaces/http.interface';
import { BaseHttp } from './base-http';

@Injectable()
export class MainHttp extends BaseHttp implements IHttp {

    constructor(http: HttpClient) {
        super(http);
    }
}